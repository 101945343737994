var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WeveDialog",
    {
      attrs: { visible: true, title: "Edit User", size: "lg" },
      on: {
        close: function ($event) {
          _vm.open = false
        },
      },
    },
    [
      _c(
        "RtbRow",
        [
          _c(
            "RtbCol",
            { attrs: { xs: "6" } },
            [
              _c("RtbTextInput", {
                attrs: {
                  label: _vm.obj.conference ? "Conference Name" : "First Name",
                },
                model: {
                  value: _vm.obj.firstname,
                  callback: function ($$v) {
                    _vm.$set(_vm.obj, "firstname", $$v)
                  },
                  expression: "obj.firstname",
                },
              }),
            ],
            1
          ),
          !_vm.obj.conference
            ? _c(
                "RtbCol",
                { attrs: { xs: "6" } },
                [
                  _c("RtbTextInput", {
                    attrs: { label: "Last Name" },
                    model: {
                      value: _vm.obj.lastname,
                      callback: function ($$v) {
                        _vm.$set(_vm.obj, "lastname", $$v)
                      },
                      expression: "obj.lastname",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbTextInput", {
                attrs: { label: "Image URL", type: "text" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [_c("RtbInlineHelp", [_vm._v(" Placeholder ")])]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.obj.image,
                  callback: function ($$v) {
                    _vm.$set(_vm.obj, "image", $$v)
                  },
                  expression: "obj.image",
                },
              }),
            ],
            1
          ),
          _vm.sessionCustomInputType
            ? _c(
                "RtbCol",
                { attrs: { xs: "4" } },
                [
                  _c("RtbTextInput", {
                    attrs: { label: _vm.sessionCustomInputType, type: "text" },
                    model: {
                      value: _vm.obj.identifier,
                      callback: function ($$v) {
                        _vm.$set(_vm.obj, "identifier", $$v)
                      },
                      expression: "obj.identifier",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbSelect", {
                attrs: {
                  options: _vm.roles,
                  identity: "value",
                  label: "Role",
                  "option-text": "text",
                  disabled: !_vm.permissions.update,
                },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [_c("RtbInlineHelp", [_vm._v("Access Roles")])]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.role,
                  callback: function ($$v) {
                    _vm.role = $$v
                  },
                  expression: "role",
                },
              }),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbSelect", {
                attrs: {
                  options: _vm.teamsWithNumOfPlayers,
                  identity: "id",
                  label: "Move To Team",
                  "option-text": "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [_vm._v("Move to another team")]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.obj.teamID,
                  callback: function ($$v) {
                    _vm.$set(_vm.obj, "teamID", $$v)
                  },
                  expression: "obj.teamID",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          !_vm.isGamePage
            ? [
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Speaker" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "help",
                        fn: function () {
                          return [
                            _c("RtbInlineHelp", [
                              _vm._v(
                                " Can access the video announcement recorder "
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    4054268220
                  ),
                  model: {
                    value: _vm.obj.speaker,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "speaker", $$v)
                    },
                    expression: "obj.speaker",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "No Raffle" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "help",
                        fn: function () {
                          return [_c("RtbInlineHelp", [_vm._v(" No raffel ")])]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3878133244
                  ),
                  model: {
                    value: _vm.obj.noRaffle,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "noRaffle", $$v)
                    },
                    expression: "obj.noRaffle",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Live Chat FTUX" },
                  model: {
                    value: _vm.obj.hasLiveChatFtux,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "hasLiveChatFtux", $$v)
                    },
                    expression: "obj.hasLiveChatFtux",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Mute" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "help",
                        fn: function () {
                          return [_c("RtbInlineHelp", [_vm._v(" Mute user")])]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3205388639
                  ),
                  model: {
                    value: _vm.obj.muted,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "muted", $$v)
                    },
                    expression: "obj.muted",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Perma-mute" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "help",
                        fn: function () {
                          return [
                            _c("RtbInlineHelp", [_vm._v(" Permanently mute ")]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3368812801
                  ),
                  model: {
                    value: _vm.obj.permanentMuted,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "permanentMuted", $$v)
                    },
                    expression: "obj.permanentMuted",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Skip" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "help",
                        fn: function () {
                          return [_c("RtbInlineHelp", [_vm._v(" Skip ")])]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    755861510
                  ),
                  model: {
                    value: _vm.obj.skip,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "skip", $$v)
                    },
                    expression: "obj.skip",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "VIP" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "help",
                        fn: function () {
                          return [_c("RtbInlineHelp", [_vm._v(" VIP ")])]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    972933832
                  ),
                  model: {
                    value: _vm.obj.vip,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "vip", $$v)
                    },
                    expression: "obj.vip",
                  },
                }),
              ]
            : [
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Mute" },
                  scopedSlots: _vm._u([
                    {
                      key: "help",
                      fn: function () {
                        return [_c("RtbInlineHelp", [_vm._v(" Mute user ")])]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.obj.muted,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "muted", $$v)
                    },
                    expression: "obj.muted",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Perma-mute" },
                  scopedSlots: _vm._u([
                    {
                      key: "help",
                      fn: function () {
                        return [
                          _c("RtbInlineHelp", [_vm._v(" Permanently mute ")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.obj.permanentMuted,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "permanentMuted", $$v)
                    },
                    expression: "obj.permanentMuted",
                  },
                }),
                _c("RtbCheckbox", {
                  staticClass: "mr-2",
                  attrs: { label: "Skip" },
                  scopedSlots: _vm._u([
                    {
                      key: "help",
                      fn: function () {
                        return [_c("RtbInlineHelp", [_vm._v(" Skip ")])]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.obj.skip,
                    callback: function ($$v) {
                      _vm.$set(_vm.obj, "skip", $$v)
                    },
                    expression: "obj.skip",
                  },
                }),
              ],
          _c("RtbCheckbox", {
            staticClass: "mr-2",
            attrs: { label: "Conference Room" },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [_c("RtbInlineHelp", [_vm._v(" Conference Room ")])]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.obj.conference,
              callback: function ($$v) {
                _vm.$set(_vm.obj, "conference", $$v)
              },
              expression: "obj.conference",
            },
          }),
          _c("RtbCheckbox", {
            staticClass: "mr-2",
            attrs: { label: "Presenter" },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [_c("RtbInlineHelp", [_vm._v(" Presenter ")])]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.presenterComputed,
              callback: function ($$v) {
                _vm.presenterComputed = $$v
              },
              expression: "presenterComputed",
            },
          }),
          _c("RtbCheckbox", {
            staticClass: "mr-2",
            attrs: { label: "Observer" },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [_c("RtbInlineHelp", [_vm._v(" Observer ")])]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.observerComputed,
              callback: function ($$v) {
                _vm.observerComputed = $$v
              },
              expression: "observerComputed",
            },
          }),
          _c("RtbCheckbox", {
            staticClass: "mr-2",
            attrs: { label: "Mobile" },
            scopedSlots: _vm._u([
              {
                key: "help",
                fn: function () {
                  return [_c("RtbInlineHelp", [_vm._v(" Mobile ")])]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.mobileComputed,
              callback: function ($$v) {
                _vm.mobileComputed = $$v
              },
              expression: "mobileComputed",
            },
          }),
        ],
        2
      ),
      _c(
        "VolumeSlider",
        {
          staticStyle: { "margin-left": "auto" },
          model: {
            value: _vm.volume,
            callback: function ($$v) {
              _vm.volume = $$v
            },
            expression: "volume",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-left": "0",
                "margin-right": "3px",
                color: "#fff",
                "line-height": "1",
              },
            },
            [_vm._v(" " + _vm._s(_vm.obj && _vm.obj.firstname) + "'s SFX ")]
          ),
        ]
      ),
      _c(
        "RtbCardActions",
        [
          !!_vm.game
            ? [
                _vm.game.moderatorID !== _vm.obj.id
                  ? _c(
                      "RtbButton",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.setGameModeratorID(_vm.obj.id)
                          },
                        },
                      },
                      [_vm._v(" Make Moderator ")]
                    )
                  : _c(
                      "RtbButton",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.setGameModeratorID(null)
                          },
                        },
                      },
                      [_vm._v(" Make " + _vm._s(_vm.obj.role) + " ")]
                    ),
                _vm.isHybridRoom
                  ? [
                      !_vm.isUserIRLSpeaker(_vm.obj)
                        ? _c(
                            "RtbButton",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.makeSpeaker(_vm.obj)
                                },
                              },
                            },
                            [_vm._v(" Make Speaker ")]
                          )
                        : _c(
                            "RtbButton",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.revokeSpeaker(_vm.obj)
                                },
                              },
                            },
                            [_vm._v(" Make Listener ")]
                          ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.isSuper
            ? _c(
                "WeveTooltip",
                {
                  staticClass: "ml-2 mr-2",
                  attrs: { placement: "bottom" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var attrs = ref.attrs
                          return [
                            _c(
                              "RtbButton",
                              _vm._b(
                                {
                                  attrs: {
                                    color: "danger",
                                    disabled:
                                      _vm.working || !_vm.permissions.delete,
                                  },
                                  on: { click: _vm.remove },
                                },
                                "RtbButton",
                                attrs,
                                false
                              ),
                              [_vm._v(" Ban ")]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    156168065
                  ),
                },
                [
                  _c("span", [
                    _vm._v("Permanently delete all records about the user"),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "WeveTooltip",
            {
              attrs: { placement: "bottom" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var attrs = ref.attrs
                    return [
                      _c(
                        "RtbButton",
                        _vm._b(
                          {
                            attrs: { color: "danger", disabled: _vm.working },
                            on: { click: _vm.banish },
                          },
                          "RtbButton",
                          attrs,
                          false
                        ),
                        [_vm._v(" Kick ")]
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Kick out of the game")])]
          ),
        ],
        2
      ),
      _c(
        "RtbCardActions",
        [
          _c(
            "RtbButton",
            {
              attrs: { color: "grey", disabled: _vm.working },
              on: { click: _vm.close },
            },
            [_vm._v(" CANCEL ")]
          ),
          _c(
            "RtbButton",
            {
              attrs: {
                disabled:
                  _vm.working ||
                  !_vm.differs ||
                  (!_vm.permissions.update && !_vm.isSameUser),
              },
              on: { click: _vm.update },
            },
            [_vm._v(" UPDATE ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }