<template>
  <WeveDialog :visible="true" title="Edit User" size="lg" @close="open = false">
    <RtbRow>
      <RtbCol xs="6">
        <RtbTextInput
          v-model="obj.firstname"
          :label="obj.conference ? 'Conference Name' : 'First Name'"
        />
      </RtbCol>
      <RtbCol xs="6" v-if="!obj.conference">
        <RtbTextInput v-model="obj.lastname" label="Last Name" />
      </RtbCol>
      <RtbCol xs="4">
        <RtbTextInput v-model="obj.image" label="Image URL" type="text">
          <template #help>
            <RtbInlineHelp> Placeholder </RtbInlineHelp>
          </template>
        </RtbTextInput>
      </RtbCol>
      <RtbCol xs="4" v-if="sessionCustomInputType">
        <RtbTextInput
          v-model="obj.identifier"
          :label="sessionCustomInputType"
          type="text"
        />
      </RtbCol>
      <RtbCol xs="4">
        <RtbSelect
          v-model="role"
          :options="roles"
          identity="value"
          label="Role"
          option-text="text"
          :disabled="!permissions.update"
        >
          <template #help>
            <RtbInlineHelp>Access Roles</RtbInlineHelp>
          </template>
        </RtbSelect>
      </RtbCol>
      <RtbCol xs="4">
        <RtbSelect
          v-model="obj.teamID"
          :options="teamsWithNumOfPlayers"
          identity="id"
          label="Move To Team"
          option-text="name"
        >
          <template #help>
            <RtbInlineHelp>Move to another team</RtbInlineHelp>
          </template>
        </RtbSelect>
      </RtbCol>
    </RtbRow>
    <div class="mt-3">
      <template v-if="!isGamePage">
        <RtbCheckbox v-model="obj.speaker" label="Speaker" class="mr-2">
          <template #help>
            <RtbInlineHelp>
              Can access the video announcement recorder
            </RtbInlineHelp>
          </template>
        </RtbCheckbox>
        <RtbCheckbox v-model="obj.noRaffle" label="No Raffle" class="mr-2">
          <template #help>
            <RtbInlineHelp> No raffel </RtbInlineHelp>
          </template>
        </RtbCheckbox>
        <RtbCheckbox
          v-model="obj.hasLiveChatFtux"
          label="Live Chat FTUX"
          class="mr-2"
        >
        </RtbCheckbox>
        <RtbCheckbox v-model="obj.muted" label="Mute" class="mr-2">
          <template #help>
            <RtbInlineHelp> Mute user</RtbInlineHelp>
          </template>
        </RtbCheckbox>
        <RtbCheckbox
          v-model="obj.permanentMuted"
          label="Perma-mute"
          class="mr-2"
        >
          <template #help>
            <RtbInlineHelp> Permanently mute </RtbInlineHelp>
          </template>
        </RtbCheckbox>
        <RtbCheckbox v-model="obj.skip" label="Skip" class="mr-2">
          <template #help>
            <RtbInlineHelp> Skip </RtbInlineHelp>
          </template>
        </RtbCheckbox>
        <RtbCheckbox v-model="obj.vip" label="VIP" class="mr-2">
          <template #help>
            <RtbInlineHelp> VIP </RtbInlineHelp>
          </template>
        </RtbCheckbox>
      </template>
      <template v-else>
        <RtbCheckbox v-model="obj.muted" label="Mute" class="mr-2">
          <template #help>
            <RtbInlineHelp> Mute user </RtbInlineHelp>
          </template>
        </RtbCheckbox>
        <RtbCheckbox
          v-model="obj.permanentMuted"
          label="Perma-mute"
          class="mr-2"
        >
          <template #help>
            <RtbInlineHelp> Permanently mute </RtbInlineHelp>
          </template>
        </RtbCheckbox>
        <RtbCheckbox v-model="obj.skip" label="Skip" class="mr-2">
          <template #help>
            <RtbInlineHelp> Skip </RtbInlineHelp>
          </template>
        </RtbCheckbox>
      </template>
      <RtbCheckbox
        v-model="obj.conference"
        label="Conference Room"
        class="mr-2"
      >
        <template #help>
          <RtbInlineHelp> Conference Room </RtbInlineHelp>
        </template>
      </RtbCheckbox>
      <RtbCheckbox v-model="presenterComputed" label="Presenter" class="mr-2">
        <template #help>
          <RtbInlineHelp> Presenter </RtbInlineHelp>
        </template>
      </RtbCheckbox>
      <RtbCheckbox v-model="observerComputed" label="Observer" class="mr-2">
        <template #help>
          <RtbInlineHelp> Observer </RtbInlineHelp>
        </template>
      </RtbCheckbox>
      <RtbCheckbox v-model="mobileComputed" label="Mobile" class="mr-2">
        <template #help>
          <RtbInlineHelp> Mobile </RtbInlineHelp>
        </template>
      </RtbCheckbox>
    </div>

    <VolumeSlider v-model="volume" style="margin-left: auto">
      <div
        style="margin-left: 0; margin-right: 3px; color: #fff; line-height: 1"
      >
        {{ obj && obj.firstname }}'s SFX
      </div>
    </VolumeSlider>

    <RtbCardActions>
      <template v-if="!!game">
        <RtbButton
          v-if="game.moderatorID !== obj.id"
          @click="setGameModeratorID(obj.id)"
        >
          Make Moderator
        </RtbButton>
        <RtbButton v-else @click="setGameModeratorID(null)">
          Make {{ obj.role }}
        </RtbButton>
        <template v-if="isHybridRoom">
          <RtbButton v-if="!isUserIRLSpeaker(obj)" @click="makeSpeaker(obj)">
            Make Speaker
          </RtbButton>
          <RtbButton v-else @click="revokeSpeaker(obj)">
            Make Listener
          </RtbButton>
        </template>
      </template>

      <WeveTooltip placement="bottom" v-if="isSuper" class="ml-2 mr-2">
        <template #activator="{ attrs }">
          <RtbButton
            v-bind="attrs"
            color="danger"
            :disabled="working || !permissions.delete"
            @click="remove"
          >
            Ban
          </RtbButton>
        </template>
        <span>Permanently delete all records about the user</span>
      </WeveTooltip>
      <WeveTooltip placement="bottom">
        <template #activator="{ attrs }">
          <RtbButton
            v-bind="attrs"
            color="danger"
            :disabled="working"
            @click="banish"
          >
            Kick
          </RtbButton>
        </template>
        <span>Kick out of the game</span>
      </WeveTooltip>
    </RtbCardActions>
    <RtbCardActions>
      <RtbButton color="grey" :disabled="working" @click="close">
        CANCEL
      </RtbButton>
      <RtbButton
        :disabled="working || !differs || (!permissions.update && !isSameUser)"
        @click="update"
      >
        UPDATE
      </RtbButton>
    </RtbCardActions>
  </WeveDialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { Role } from "@/helpers"
import { db } from "@/firebase"
import User from "@shared/User"
import UserService from "@/services/user.service"
import _ from "lodash"
import Team from "@shared/Team"
import UserSoundService from "@/services/user-sound.service"
import { WeveDialog, WeveTooltip } from "@weve/ui"

import {
  RtbRow,
  RtbCol,
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbSelect,
  RtbCheckbox,
  RtbInlineHelp
} from "@/components/ui"

import VolumeSlider from "@/components/GroupTeams/Misc/VolumeSlider.vue"

const USER_ACCESS_LEVELS_MAP = {}
USER_ACCESS_LEVELS_MAP[Role.Host] = "Host"
USER_ACCESS_LEVELS_MAP[Role.Super] = "Super Host"
USER_ACCESS_LEVELS_MAP[Role.Player] = "Player"
USER_ACCESS_LEVELS_MAP[Role.Audit] = "Auditor"
USER_ACCESS_LEVELS_MAP[Role.Spectator] = "Spectator"

const USER_ACCESS_LEVELS = Object.keys(USER_ACCESS_LEVELS_MAP).map(key => ({
  value: key,
  text: USER_ACCESS_LEVELS_MAP[key]
}))

const Permissions = {
  update: ({ super: isSuper, role } = {}) => {
    if (isSuper && role === Role.Host) {
      return Object.keys(USER_ACCESS_LEVELS_MAP)
    }
    if (role === Role.Host) {
      return [Role.Player, Role.Audit, Role.Spectator]
    }
    if (role === Role.Audit) {
      return [Role.Player]
    }
    return []
  },
  delete: ({ super: isSuper, role } = {}) => {
    if (isSuper && role === Role.Host) {
      return Object.keys(USER_ACCESS_LEVELS_MAP)
    }
    if (role === Role.Host) {
      return [Role.Player, Role.Audit, Role.Spectator]
    }
    return []
  }
}

function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base)
}

export default {
  name: "UserEditor",
  components: {
    WeveDialog,
    WeveTooltip,
    RtbRow,
    RtbCol,
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbSelect,
    RtbCheckbox,
    RtbInlineHelp,
    VolumeSlider
  },
  data() {
    return {
      currentTeamID: 0,
      obj: null,
      roles: USER_ACCESS_LEVELS,
      working: false,
      theTeams: []
    }
  },
  created() {
    this.obj = { ..._.cloneDeep(this.user) }
  },
  computed: {
    ...mapGetters("UserSettings", ["user"]),
    ...mapGetters("auth", ["token", "isSuper"]),
    ...mapGetters("auth", { currentUser: "user" }),
    ...mapGetters({ game: "actualGame" }),
    ...mapGetters("group", ["isUserIRLSpeaker"]),
    ...mapGetters({
      teams: "chats",
      users: "onlineUsersArray"
    }),
    teamsWithNumOfPlayers() {
      const users = this.users
      return Team.normalize(this.teams || {}).map(team => ({
        ...team,
        name:
          team.name +
          " (" +
          users.filter(({ teamID }) => teamID === team.id).length +
          ")"
      }))
    },
    sessionCustomInputType() {
      return this.$store.state.auth.client?.customInputType
    },
    presenterComputed: {
      get() {
        return Boolean(this.obj.presenter)
      },
      set(value) {
        if (value) this.obj.observer = null
        this.obj = { ...this.obj, presenter: Boolean(value) }
      }
    },
    mobileComputed: {
      get() {
        return Boolean(this.obj.mobile)
      },
      set(value) {
        this.obj = { ...this.obj, mobile: Boolean(value) }
      }
    },
    observerComputed: {
      get() {
        return Boolean(this.obj.observer)
      },
      set(value) {
        if (value) this.obj.presenter = null
        this.obj = { ...this.obj, observer: Boolean(value) }
      }
    },
    isHybridRoom() {
      return this.$store.getters["auth/isHybridRoom"]
    },
    volume: {
      get() {
        return (
          (isNaN(this.obj?.volume) || this.obj?.volume < 0
            ? 0.25
            : this.obj?.volume) * 100
        )
      },
      set: _.debounce(
        function (value) {
          const volume = Math.round((value / 100) * 100) / 100
          this.obj = { ...this.obj, volume }
        },
        1000,
        { leading: true }
      )
    },
    isCurrentUserAuditor() {
      return this.currentUser?.role === Role.Audit
    },
    open: {
      get() {
        return true
      },
      set(value) {
        if (!value)
          this.$store.commit("UserSettings/UPDATE_USER", { user: null })
      }
    },
    differs() {
      return JSON.stringify(this.user) !== JSON.stringify(this.obj)
    },
    isGamePage() {
      return this.$route.name === "game" || this.$route.name === "pickteams"
    },
    role: {
      get() {
        if (this.obj?.super && this.obj?.role === Role.Host) {
          return Role.Super
        } else {
          return this.obj?.role
        }
      },
      async set(value) {
        try {
          if (value === Role.Super) {
            await this.$confirm({
              message: `${this.obj.firstname} ${this.obj.lastname} will be able to give root access to other users`,
              buttonColor: "danger"
            })
            this.$set(this.obj, "role", Role.Host)
          } else {
            if (this.obj.super) this.$set(this.obj, "super", null)
            this.$set(this.obj, "role", value)
          }
        } catch (e) {
          this.$info("Aborted")
          this.close()
        }
      }
    },
    permissions() {
      return {
        update: Permissions.update(this.currentUser).includes(this.role),
        delete: Permissions.delete(this.currentUser).includes(this.role)
      }
    },
    isSameUser() {
      return this.currentUser?.id === this.user?.id
    }
  },
  methods: {
    ...mapActions(["setGameModeratorID"]),
    ...mapActions("Games", ["updateGameAny"]),
    makeSpeaker: UserSoundService.makeSpeaker,
    revokeSpeaker: UserSoundService.revokeSpeaker,
    getSpecialPurposeTeam(user) {
      const isTeamEmpty = team => {
        const users = this.$store.getters.onlineUsersArray.filter(
          u => u.teamID === team.id && u.id !== user.id
        )
        if (users.length === 0) return true
        if (User.isPresenter(user) && users.some(User.isPresenter)) return false
        if (User.isObserver(user) && users.some(User.isObserver)) return false

        return true
      }

      return Team.normalize(this.$store.getters.chats || {}).find(
        team => Team.isSpecialPurpose(team) && isTeamEmpty(team)
      )
    },
    async createSpecialPurposeTeam() {
      const gameID = this.$store.getters.gameID
      const orgID = this.$store.getters.orgID
      if (!gameID) return
      if (!orgID) return
      const team = new Team({
        name: `Special Purpose Team`,
        show: true,
        specialPurpose: true
      })
      await db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/teams/${team.id}`)
        .set(team)
      return team
    },
    async update() {
      try {
        this.working = true

        if (!this.permissions.update && !this.isSameUser)
          throw new Error("No permission to update user")

        const update = {}

        if (User.isSpecialPurposeUser(this.obj)) {
          const specialPurposeTeam =
            this.getSpecialPurposeTeam(this.obj) ||
            (await this.createSpecialPurposeTeam())

          this.obj.teamID = specialPurposeTeam?.id || null
        }

        const diff = difference(this.obj, this.user)

        if (this.role === Role.Super && diff.role) {
          update[`access/0/${this.user.id}`] = true
          update[`access/1/${this.user.id}`] = null
        } else if (diff.role) {
          if (this.role === Role.Host) {
            update[`access/0/${this.user.id}`] = null
            update[`access/1/${this.user.id}`] = true
          } else if (diff.super) {
            update[`access/0/${this.user.id}`] = null
            update[`access/1/${this.user.id}`] = null
          }
        }

        Object.keys(diff).forEach(key => {
          update[`org/1/users/${this.user.id}/${key}`] =
            diff[key] == null ? null : diff[key]
        })

        const auxUpdate = {}

        if (diff.teamID) {
          auxUpdate[`org/1/users/${this.user.id}/teamID`] = diff.teamID
        }

        if (diff.muted) {
          auxUpdate[`org/1/users/${this.user.id}/muted`] = diff.muted
        }

        const promises = []

        if (Object.keys(auxUpdate).length > 0) {
          promises.push(db.auxiliary().ref().update(auxUpdate))
        }

        if (Object.keys(update).length > 0) {
          promises.push(db.ref().update(update))
        }

        await Promise.all(promises)
      } catch (e) {
        console.error(e)
        this.$info(e?.message)
      } finally {
        this.working = false
        this.close()
      }
    },
    async banish() {
      try {
        if (
          !confirm(
            "Are you sure you want to kick this player out of the game this player?"
          )
        )
          return
        this.working = true
        if (!this.user.id) throw new Error("No user ID")
        await db
          .ref(`org/1/users/${this.user.id}`)
          .update({ clientID: null, gameID: null, path: null })
      } catch (e) {
        console.error(e)
        this.$info(e?.message)
      } finally {
        this.working = false
        this.close()
      }
    },
    async remove() {
      try {
        if (!confirm("Are you sure you want to ban the player?")) return
        this.working = true
        if (!this.user.id) throw new Error("No user ID")
        await UserService.remove(this.user.id)
      } catch (e) {
        alert(e?.response?.data?.message)
        console.error(e, e.response)
      } finally {
        this.working = false
        this.close()
      }
    },
    close() {
      this.open = false
    }
  }
}
</script>
